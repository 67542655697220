<template>
  <v-card outlined class="px-8 py-4">
    <v-row>
      <v-col cols="12" sm="6" lg="5" class="py-4 px-4">
        <v-layout align-center class="text-sm-left" fill-height>
          <img
            class="logoPasswrd"
            v-if="logo"
            :src="serverUrl + '/images/' + logo + '?token=' + userToken"
          />
          <img
            v-else
            class="logoPasswrd"
            :src="getLogo"
            @error="failedImage = true"
          />
        </v-layout>
      </v-col>
      <v-col cols="12" sm="6" lg="7" class="py-4 px-4">
        <languageSelector
          :addContainer="true"
          :cssProps="'language-selector-in-row max-w-select-lang-login'"
        />

        <br />
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-row no-gutters>
            <v-col cols="12" lg="4" md="4">
              <v-text-field
                :label="$t('First name*')"
                v-model="requestUser.FirstName"
                required
                validate-on-blur
                :rules="getRules('notEmptyStringRule')"
                outlined
                dense
                class="mb-2"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="8" md="8">
              <v-text-field
                :label="$t('Last name*')"
                v-model="requestUser.LastName"
                required
                validate-on-blur
                class="ml-md-2 mb-2"
                :rules="getRules('notEmptyStringRule')"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-text-field
            :label="$t('Email*')"
            v-model="requestUser.Email"
            prepend-inner-icon="mdi-email"
            required
            validate-on-blur
            :rules="getRules('notEmptyEmailRule')"
            class="mb-2"
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
          <v-row no-gutters>
            <v-col cols="12" lg="4" md="4">
              <v-text-field
                :label="$t('Phone number')"
                v-model="requestUser.PhoneNumber"
                validate-on-blur
                prepend-inner-icon="mdi-phone"
                class="mb-2"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="8" md="8">
              <v-text-field
                class="ml-md-2 mb-2"
                :label="$t('Company')"
                v-model="requestUser.Company"
                prepend-inner-icon="mdi-domain"
                validate-on-blur
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-4" justify="end">
            <v-spacer></v-spacer>
            <v-btn depressed text @click="$router.go(-1)" class="mt-2 text-right">
              <v-icon left> mdi-arrow-left </v-icon>{{ $t("Cancel") }}</v-btn
            >

            <v-btn
              depressed
              @click="sendRequestAccess()"
              :loading="loading"
              color="primary"
              class="text-right mt-2 ml-2"
            >
              {{ $t("Request access") }}
            </v-btn>
          </v-row>

          <errorHandeling
            v-if="success.length > 0"
            :buttons="[
              {
                isText: true,
                functionName: 'clearError',
                text: 'Close',
              },
            ]"
            :snackbarText="success"
            snackbarColor="success"
            snackbarTimout="-1"
            snackbarIcon="mdi-check"
            @clearError="success = ''"
          />
          <errorHandeling
            v-if="error"
            :snackbarText="error"
            :buttons="[
              {
                isText: true,
                functionName: 'clearError',
                text: 'Close',
              },
            ]"
            snackbarColor="error"
            snackbarTimout="-1"
            snackbarIcon="mdi-alert"
            @clearError="$store.dispatch('resetError')"
          />
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>
  
  <script>
import { mapGetters } from "vuex";
import validation from "../../validation";

export default {
  data() {
    return {
      logo: this.$config.logoPos,
      serverUrl: this.$helpers.getServerUrl(),
      success: "",
      email: "",
      loading: false,
      userToken: this.$auth.userToken(),
      valid: true,
      failedImage: false,
      requestUser: {
        FirstName: null,
        LastName: null,
        Email: null,
        Phone: null,
        Company: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      error: "error",
    }),
    getLogo: {
      get() {
        return this.failedImage
          ? this.serverUrl +
              "/images/" +
              "logo-pos.png" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate()
          : this.serverUrl +
              "/images/" +
              "logo-pos.svg" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate();
      },
    },
  },
  methods: {
    getRules(rule) {
      return validation.getValidation(rule);
    },
    sendRequestAccess() {
      this.success = "";

      if (this.$refs.form.validate()) {
        this.loading = true;

        this.$request.post("/requestaccess", this.requestUser, (res) => {
          this.loading = false;

          if (res.success) {
            this.success = res.message;
            this.$store.dispatch("resetError");
          } else {
            this.$store.dispatch("setError", res);
          }
        });
      }
    },
    getDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return mm + dd + yyyy;
    },
  },
};
</script>
  
  <style scoped>
.logoPasswrd {
  max-width: 100%;
  width: 90%;
  margin: auto;
}
</style>
  